@tailwind base;
@tailwind components;
@tailwind utilities;

*, html, body, main {
  scroll-behavior: smooth !important;
}
body {
  background-color: black;
}
.pl {
	display: block;
	width: 6.25em;
	height: 6.25em;
}
.pl__ring, .pl__ball {
	animation: ring 2s ease-out infinite;
}
.pl__ball {
	animation-name: ball;
}
@keyframes ring {
	from {
		stroke-dasharray: 0 257 0 0 1 0 0 258;
	}
	25% {
		stroke-dasharray: 0 0 0 0 257 0 258 0;
	}
	50%, to {
		stroke-dasharray: 0 0 0 0 0 515 0 0;
	}
}
@keyframes ball {
	from, 50% {
		animation-timing-function: ease-in;
		stroke-dashoffset: 1;
	}
	64% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -109;
	}
	78% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -145;
	}
	92% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -157;
	}
	57%, 71%, 85%, 99%, to {
		animation-timing-function: ease-out;
		stroke-dashoffset: -163;
	}
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#menu03.active {
  transform: rotate(360deg);
}
#menu03.active span:nth-of-type(1) {
  transform: translateY(9px) rotate(-45deg);
}
#menu03.active span:nth-of-type(2) {
  transform: translateY(0) rotate(45deg);
}
#menu03.active span:nth-of-type(3) {
  opacity: 0;
}
.underline-animation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: width 0.3s ease-in-out;
}
.tabcontainer {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.tabcontainer div {
  position: relative;
  padding-bottom: 3px;
  cursor: pointer;
  border-radius: 0.75rem;
}

.tabcontainer div:not(.selected) .underline-animation {
  width: 0%;
}

.tabcontainer div.selected .underline-animation {
  width: 100%;
}
.linkedin-filter:hover{
  filter: invert(24%) sepia(44%) saturate(5703%) hue-rotate(186deg) brightness(91%) contrast(101%);
  transition: all ease-in-out;
}
.email-icon {
  filter: invert(86%) sepia(16%) saturate(5049%) hue-rotate(172deg) brightness(104%) contrast(98%);
}

/* Initially, hide the last image */
.last-image {
  display: none;
}

/* When hovering over the <a> element, hide the first image and show the last image */
.image-link:hover .first-image {
  display: none;
}

.image-link:hover .last-image {
  display: block;
}


.animate {
  opacity: 0;
}

.animate.shown {
  
  animation: fadeInUp 1s 1;
  opacity: 1;
}
.shown {
  
  animation: fadeInUp 1s 1;
}
.shown-2 {
  
  transition-delay: 200ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 200ms;
  opacity: 1;

}
.shown-4 {
  
  transition-delay: 400ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 400ms;
  opacity: 1;

}
.shown-6 {
  
  transition-delay: 600ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 600ms;
  opacity: 1;

}
.animate.shown-1 {
  transition-delay: 100ms;
  animation: fadeInUp 1.7s 1;
  animation-delay: 100ms;
  opacity: 1;
}
.animate.shown-2 {
  transition-delay: 200ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 200ms;
  opacity: 1;
}
.animate.shown-3 {
  transition-delay: 300ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 300ms;
  opacity: 1;
}

.animate.shown-4 {
  transition-delay: 400ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 400ms;
  opacity: 1;
}
.animate.shown-5 {
  transition-delay: 500ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 500ms;
  opacity: 1;
}
.animate.shown-6 {
  transition-delay: 600ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 600ms;
  opacity: 1;
}
.animate.shown-7 {
  transition-delay: 700ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 700ms;
  opacity: 1;
}
.animate.shown-8 {
  transition-delay: 800ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 800ms;
  opacity: 1;
}
.animate.shown-9 {
  transition-delay: 900ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 900ms;
  opacity: 1;
}
.animate.shown-10 {
  transition-delay: 1000ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1000ms;
  opacity: 1;
}
.animate.shown-11 {
  transition-delay: 1100ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1100ms;
  opacity: 1;
}
.animate.shown-12 {
  transition-delay: 1200ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1200ms;
  opacity: 1;
}
.animate.shown-13 {
  transition-delay: 1300ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1300ms;
  opacity: 1;
}
.animate.shown-14 {
  transition-delay: 1400ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1400ms;
  opacity: 1;
}
.animate.shown-15 {
  transition-delay: 1500ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1500ms;
  opacity: 1;
}
.animate.shown-16 {
  transition-delay: 1600ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1600ms;
  opacity: 1;
}
.animate.shown-17 {
  transition-delay: 1700ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1700ms;
  opacity: 1;
}
.animate.shown-18 {
  transition-delay: 1800ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1800ms;
  opacity: 1;
}
.animate.shown-19 {
  transition-delay: 1900ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 1900ms;
  opacity: 1;
}
.animate.shown-20 {
  transition-delay: 2000ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2000ms;
  opacity: 1;
}
.animate.shown-21 {
  transition-delay: 2100ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2100ms;
  opacity: 1;
}
.animate.shown-22 {
  transition-delay: 2200ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2200ms;
  opacity: 1;
}
.animate.shown-23 {
  transition-delay: 2300ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2300ms;
  opacity: 1;
}
.animate.shown-24 {
  transition-delay: 2400ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2400ms;
  opacity: 1;
}
.animate.shown-25 {
  transition-delay: 2500ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2500ms;
  opacity: 1;
}
.animate.shown-26 {
  transition-delay: 2600ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2600ms;
  opacity: 1;
}
.animate.shown-27 {
  transition-delay: 2700ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2700ms;
  opacity: 1;
}
.animate.shown-28 {
  transition-delay: 2800ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2800ms;
  opacity: 1;
}
.animate.shown-29 {
  transition-delay: 2900ms;
  animation: fadeInUp 2.3s 1;
  animation-delay: 2900ms;
  opacity: 1;
}

.card {
  display: grid;
  grid-template-columns: 300px;
  grid-template-rows: 210px 210px 80px;
  grid-template-areas: "image" "text" "stats";

  border-radius: 18px;
  background: white;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.9);
  text-align: center;
}

.card-image {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%),url("https://firebasestorage.googleapis.com/v0/b/inventum-98847.appspot.com/o/icons%2Fphone-receiver-silhouette.png?alt=media&token=88cf708a-e8db-4ab9-ad78-69e5f61ed9a8");
  grid-area: image;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-size: cover;
}

.card-text {
  grid-area: text;
  margin: 25px;
}
.card-text .date {
  color: rgb(255, 7, 110);
  font-size:13px;
}
.card-text p {
  color: grey;
  font-size:15px;
  font-weight: 300;
}
.card-text h2 {
  margin-top:0px;
  font-size:28px;
}

.rgb::after {
  content:"";
  background: linear-gradient(45deg,
  #ff0000 0%, 
  #ff9a00 10%,
  #d0de21 20%,
  #4fdc4a 30%, 
  #3fdad8 40%,
  #2fc9e2 50%, 
  #1c7fee 60%, 
  #5f15f2 70%, 
  #ba0cf8 80%, 
  #fb07d9 90%, 
  #ff0000 100%  )
  repeat 0% 0% / 300% 100%;
  position: absolute;
  inset: -3px;
  border-radius: 16px;
  filter: blur(8px);
  transform: translateZ(-1px); /*or z-index */
  animation: rgb 6s linear infinite;

}
@keyframes rgb {
  0% {background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
}
.scalebgimg:hover img {
  transform: scale(1.1);
  border-radius: 0.75rem;
  transition: all 0.3s ease-in-out;
}
.scalebgimg:not(:hover) img {
  transform: scale(1.00);
  transition: all 0.3s ease-in-out;
  border-radius: 0.75rem;
}
.scalebgimg {
  border-radius: 0.75rem;
  overflow: hidden;
}

.portfoliogrid {
  display: flex;
  flex-wrap: wrap;
}
.colourfilter {
  filter: brightness(0) saturate(100%) invert(65%) sepia(59%) saturate(493%) hue-rotate(211deg) brightness(93%) contrast(85%);


}

.custom-shape-divider-bottom-1694341407 {
  position: absolute;
  top: -151px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1694341407 svg {
  position: relative;
  display: block;
  width: calc(142% + 1.3px);
  height: 151px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1694341407 .shape-fill {
  fill: url('#og') ;
}
.custom-shape-divider {
  position: absolute;
  top: -151px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider svg {
  position: relative;
  display: block;
  width: calc(142% + 1.3px);
  height: 151px;
  transform: rotateY(180deg);
}

.custom-shape-divider .shape-fill {
  fill: url('#og') ;
}

/** end for goo container **/
.glowy-blob {
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50, -50);
  -ms-transform: translate(-50, -50);
  -webkit-transform: translate(-50, -50);
  transform: translate(-50, -50);
  -webkit-filter: url("#goo");
  filter: url("#goo");
  z-index: 10;
}
.glowy-blob div {
  width: 100px;
  height: 100px;
  background: #85ede8;
  -moz-border-radius: 500px;
  -webkit-border-radius: 500px;
  border-radius: 500px;
  display: inline-block;
  position: absolute;
  box-shadow: 0px 0px 50px #85ede8;
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
  /**keyframes **/
}
.glowy-blob div:nth-child(2) {
  width: 60px;
  height: 60px;
  left: 48px;
}
.glowy-blob div:nth-child(3) {
  width: 100px;
  height: 100px;
  left: -55px;
}
.glowy-blob div:nth-child(4) {
  width: 110px;
  height: 110px;
  left: -25px;
}
.glowy-blob div:nth-child(5) {
  width: 120px;
  height: 120px;
  left: -6px;
}
.glowy-blob div:nth-child(6) {
  width: 150px;
  height: 150px;
  left: -8px;
}
.glowy-blob div:nth-child(1) {
  top: 0;
  left: 0;
  -moz-animation: anim-1 2.5s ease-in-out infinite;
  -webkit-animation: anim-1 2.5s ease-in-out infinite;
  animation: anim-1 2.5s ease-in-out infinite;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
@-moz-keyframes anim-1 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 48px;
    top: 0;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-1 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 48px;
    top: 0;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-1 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 48px;
    top: 0;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(2) {
  top: 0;
  left: 0;
  -moz-animation: anim-2 2.5s ease-in-out infinite;
  -webkit-animation: anim-2 2.5s ease-in-out infinite;
  animation: anim-2 2.5s ease-in-out infinite;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@-moz-keyframes anim-2 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -55px;
    top: 36px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-2 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -55px;
    top: 36px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-2 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -55px;
    top: 36px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(3) {
  top: 0;
  left: 0;
  -moz-animation: anim-3 2.5s ease-in-out infinite;
  -webkit-animation: anim-3 2.5s ease-in-out infinite;
  animation: anim-3 2.5s ease-in-out infinite;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@-moz-keyframes anim-3 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -25px;
    top: -39px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-3 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -25px;
    top: -39px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-3 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -25px;
    top: -39px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(4) {
  top: 0;
  left: 0;
  -moz-animation: anim-4 2.5s ease-in-out infinite;
  -webkit-animation: anim-4 2.5s ease-in-out infinite;
  animation: anim-4 2.5s ease-in-out infinite;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
@-moz-keyframes anim-4 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -6px;
    top: 24px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-4 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -6px;
    top: 24px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-4 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -6px;
    top: 24px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(5) {
  top: 0;
  left: 0;
  -moz-animation: anim-5 2.5s ease-in-out infinite;
  -webkit-animation: anim-5 2.5s ease-in-out infinite;
  animation: anim-5 2.5s ease-in-out infinite;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@-moz-keyframes anim-5 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -8px;
    top: 17px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-5 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -8px;
    top: 17px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-5 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -8px;
    top: 17px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(6) {
  top: 0;
  left: 0;
  -moz-animation: anim-6 2.5s ease-in-out infinite;
  -webkit-animation: anim-6 2.5s ease-in-out infinite;
  animation: anim-6 2.5s ease-in-out infinite;
  -moz-animation-delay: 3s;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@-moz-keyframes anim-6 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -25px;
    top: 21px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-6 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -25px;
    top: 21px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-6 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -25px;
    top: 21px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(7) {
  top: 0;
  left: 0;
  -moz-animation: anim-7 2.5s ease-in-out infinite;
  -webkit-animation: anim-7 2.5s ease-in-out infinite;
  animation: anim-7 2.5s ease-in-out infinite;
  -moz-animation-delay: 3s;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@-moz-keyframes anim-7 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 35px;
    top: -54px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-7 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 35px;
    top: -54px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-7 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 35px;
    top: -54px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(8) {
  top: 0;
  left: 0;
  -moz-animation: anim-8 2.5s ease-in-out infinite;
  -webkit-animation: anim-8 2.5s ease-in-out infinite;
  animation: anim-8 2.5s ease-in-out infinite;
  -moz-animation-delay: 3s;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@-moz-keyframes anim-8 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -15px;
    top: 24px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-8 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -15px;
    top: 24px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-8 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -15px;
    top: 24px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(9) {
  top: 0;
  left: 0;
  -moz-animation: anim-9 2.5s ease-in-out infinite;
  -webkit-animation: anim-9 2.5s ease-in-out infinite;
  animation: anim-9 2.5s ease-in-out infinite;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
@-moz-keyframes anim-9 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -24px;
    top: 55px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-9 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -24px;
    top: 55px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-9 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -24px;
    top: 55px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(10) {
  top: 0;
  left: 0;
  -moz-animation: anim-10 2.5s ease-in-out infinite;
  -webkit-animation: anim-10 2.5s ease-in-out infinite;
  animation: anim-10 2.5s ease-in-out infinite;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@-moz-keyframes anim-10 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -34px;
    top: -10px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-10 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -34px;
    top: -10px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-10 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -34px;
    top: -10px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(11) {
  top: 0;
  left: 0;
  -moz-animation: anim-11 2.5s ease-in-out infinite;
  -webkit-animation: anim-11 2.5s ease-in-out infinite;
  animation: anim-11 2.5s ease-in-out infinite;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
@-moz-keyframes anim-11 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -30px;
    top: -45px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-11 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -30px;
    top: -45px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-11 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -30px;
    top: -45px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(12) {
  top: 0;
  left: 0;
  -moz-animation: anim-12 2.5s ease-in-out infinite;
  -webkit-animation: anim-12 2.5s ease-in-out infinite;
  animation: anim-12 2.5s ease-in-out infinite;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@-moz-keyframes anim-12 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -53px;
    top: 46px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-12 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -53px;
    top: 46px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-12 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -53px;
    top: 46px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(13) {
  top: 0;
  left: 0;
  -moz-animation: anim-13 2.5s ease-in-out infinite;
  -webkit-animation: anim-13 2.5s ease-in-out infinite;
  animation: anim-13 2.5s ease-in-out infinite;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
@-moz-keyframes anim-13 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 34px;
    top: -25px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-13 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 34px;
    top: -25px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-13 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 34px;
    top: -25px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(14) {
  top: 0;
  left: 0;
  -moz-animation: anim-14 2.5s ease-in-out infinite;
  -webkit-animation: anim-14 2.5s ease-in-out infinite;
  animation: anim-14 2.5s ease-in-out infinite;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@-moz-keyframes anim-14 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -30px;
    top: 55px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-14 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -30px;
    top: 55px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-14 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: -30px;
    top: 55px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:nth-child(15) {
  top: 0;
  left: 0;
  -moz-animation: anim-15 2.5s ease-in-out infinite;
  -webkit-animation: anim-15 2.5s ease-in-out infinite;
  animation: anim-15 2.5s ease-in-out infinite;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@-moz-keyframes anim-15 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 48px;
    top: -36px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes anim-15 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 48px;
    top: -36px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes anim-15 {
  0% {
    left: 0;
    top: 0;
  }
  50% {
    left: 48px;
    top: -36px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
.glowy-blob div:hover {
  cursor: default;
}


.object-customright {
  object-position: 93%;
}
.bg-custom-wisteria {
  background-color: rgb()
}
details {
  transition: max-height 0.3s ease-in-out; /* Adjust the duration and easing as needed */
  overflow: hidden;
  max-height: 2rem;
}

details[open] {
  max-height: 1000px; /* Set a sufficiently large value to cover the expected height */
}

.addon-modal-size {
  min-height: 50vh;
  max-height: 90vh;
  overflow-y: auto;
  height: max-content;
  width: max-content;
  min-width: 50vw;
  max-width: 90vw;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}
@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}
